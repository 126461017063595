import { createContext, useState } from "react";
import PropTypes from "prop-types";
import { SIZE } from "baseui/modal";

export const PageContext = createContext(null);

const PageContextProvider = ({ children, page }) => {
  const [additionalData, setAdditionalData] = useState({});

  const [popupMessage, setPopupMessage] = useState(null);

  const createPopupMessage = (
    text,
    title = "",
    {
      onSuccess = () => null,
      onCancel = () => null,
      cancelButtonTextOverride = null,
      submitButtonTextOverride = null,
      buttonPosition = null,
      hideButtons = false,
      hideCancelButton = false,
      buttonClasses = {
        cancel: "button--grey",
        submit: "button--bg-red",
      },
      size = SIZE.auto,
      autoFocus = true,
      additionalButtons = [],
    } = {}
  ) => {
    setPopupMessage({
      title,
      text,
      onSuccess,
      onCancel,
      cancelButtonTextOverride: cancelButtonTextOverride,
      submitButtonTextOverride: submitButtonTextOverride,
      hideButtons,
      hideCancelButton,
      buttonClasses,
      size,
      autoFocus,
      buttonPosition,
      additionalButtons,
    });
  };

  const clearPopupMessage = () => setPopupMessage(null);

  const [ceDataUpdateModalProps, setCeDataUpdateModalProps] = useState({
    ceData: null,
    modalIsOpen: false,
  });

  const openCeDataUpdateModal = (ceData) =>
    setCeDataUpdateModalProps({
      ceData,
      modalIsOpen: true,
    });

  const closeCeDataUpdateModal = (ceData) =>
    setCeDataUpdateModalProps({
      ceData,
      modalIsOpen: false,
    });

  return (
    <PageContext.Provider
      value={{
        page,
        ...additionalData,
        setAdditionalData,
        popupMessage,
        createPopupMessage,
        clearPopupMessage,
        ceDataUpdateModalProps,
        openCeDataUpdateModal,
        closeCeDataUpdateModal,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

PageContextProvider.propTypes = {
  page: PropTypes.object,
  additionalData: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default PageContextProvider;
